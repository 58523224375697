import * as React from "react"
import * as ReactDOM from "react-dom"
import "./index.css"
import {Model} from "./Model"
import {GateListener} from "./lib/gate/GateListener"
import {createBrowserHistory} from "history"
import {Gate} from "./lib/gate/Gate"
import {Router as ReactRouter} from "react-router"
import {AuthLayer} from "./components/AuthLayer"
import {Bridge} from "./Bridge"
import {getParameterByName, getQueryParams} from "./lib/functions/getParameterByName"
import dotenv from "dotenv"

dotenv.config()
const lead_source = getParameterByName("etrk")
if (lead_source && lead_source !== "") {
    localStorage.setItem("lead_source", lead_source)
}

let hasPaymentUrl: boolean
let paymentOptions: any
if (
    window.location.href.includes("?payment_intent") &&
    window.location.href.includes("redirect_status")
) {
    const domain = window.location.protocol + "//" + window.location.host
    console.log("@@@@ doamin:", window.location.href)
    hasPaymentUrl = true
    const urlParams = getQueryParams(`${window.location.href}`) as any
    // `http://admissions-salemu-ath.edular.loc/?payment_intent=pi_3NUzLQG1lzASuKS51jBbfC8j&payment_intent_client_secret=pi_3NUzLQG1lzASuKS51jBbfC8j_secret_bsNynFprF3z0Q4JLvqqddcu3h&redirect_status=succeeded`
    paymentOptions = {
        paymentIntent: urlParams.payment_intent,
        paymentIntentClientSecret: urlParams.payment_intent_client_secret,
        status: urlParams.redirect_status
    }
    window.history.replaceState({additionalInformation: `Update url from stripe.`}, "", domain)
}

document.domain = document.domain.replace("platform.", "")

const gate = new Gate()
new GateListener(gate)
const history = createBrowserHistory()
const model = new Model(gate, history)

async function main() {
    await model.auth.refreshToken()
    await model.loadMe()
    if (hasPaymentUrl) {
        await model.updatePaymentStatus(paymentOptions)
    }
}

main()

// @ts-ignore
window.model = model

// @ts-ignore
window.bridge = new Bridge(model)

ReactDOM.render(
    <ReactRouter history={history}>
        <AuthLayer gate={gate} model={model} auth={model.auth} />
    </ReactRouter>,
    document.getElementById("root")
)
